<template>
  <div>
    <div v-if="days !== null && +days > 0" class="trial-counter-bar-blue">
      <p class="my-0 txt">
        {{ $t("dashboard.freeTrialExpiresIn") }}
        <span class="days">{{ numberWithCommas(days) }}</span>
        {{ $t("dashboard.days") }}
      </p>

      <router-link
        to="/billingAndSubscription"
        class="btn yellow-button"
        :class="{ 'ar-spacing-btn': isArabicLocale }"
        >{{ $t("dashboard.subscribeNow") }}</router-link
      >
    </div>

    <div v-else-if="+days == 0" class="trial-counter-bar-red">
      <p class="my-0 txt">{{ $t("dashboard.freeTrialExpired") }}</p>

      <router-link
        to="/billingAndSubscription"
        class="btn red-button"
        :class="{ 'ar-spacing-btn': isArabicLocale }"
        >{{ $t("dashboard.subscribe") }}</router-link
      >
    </div>
  </div>
</template>

<script>
import { numberWithCommas } from "@/helpers/calculations";
import ls from "localstorage-slim";
import arabicLocale from "@/mixins/arabicLocale.js";

ls.config.encrypt = true;

export default {
  name: "TrialCounterBar",
  mixins: [arabicLocale],
  data() {
    return {
      days: ls.get("randomID"),
      subscriptionStatus: ls.get("SubscriptionStatus"),
    };
  },

  methods: {
    numberWithCommas,
  },
};
</script>

<style scoped>
.trial-counter-bar-blue {
  width: 100%;
  /* background-color: #f3bc4554; */
  /* color: #fff; */
  padding: 20px 0px 0px 0px;
  /* -webkit-box-shadow: 0px 14px 14px -7px rgb(140 136 136 / 30%);
    -moz-box-shadow: 0px 14px 14px -7px rgb(140 136 136 / 30%);
    box-shadow: 0px 14px 14px -7px rgb(140 136 136 / 30%); */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.trial-counter-bar-red {
  width: 100%;
  /* background-color: var(--red); */
  /* color: #fff; */
  padding: 20px 0px 0px 0px;
  /* -webkit-box-shadow: 0px 14px 14px -7px rgb(140 136 136 / 30%);
    -moz-box-shadow: 0px 14px 14px -7px rgb(140 136 136 / 30%);
    box-shadow: 0px 14px 14px -7px rgb(140 136 136 / 30%); */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.txt {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #002873;
}

.yellow-button {
  background-color: var(--yellow);
  color: #fff;
  border-radius: 2rem;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  border: none;
  border: 2px solid var(--yellow);
  width: 142px;
  height: 34px;
  margin-left: 24px;
}

.yellow-button:hover {
  background-color: #fff;
  color: var(--yellow);
  border: 2px solid var(--yellow);
}

.red-button {
  background-color: var(--red);
  color: #fff;
  border-radius: 12px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  border: none;
  border: 2px solid var(--red);
  width: 142px;
  height: 34px;
  margin-left: 24px;
}

.red-button:hover {
  background-color: #fff;
  color: var(--red);
  border: 2px solid var(--red);
}

.days {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  color: #f5a800;
}
.ar-spacing-btn {
  margin-left: 0px;
  margin-right: 24px;
}
@media screen and (max-width: 767px) {
  .trial-counter-bar-blue,
  .trial-counter-bar-red {
    flex-direction: column;
    text-align: center;
    padding-right: 10px;
    padding-left: 10px;
    row-gap: 10px;
  }
  .yellow-button {
    margin-left: 0px;
  }
}
</style>
