<template>
  <main id="wrapper" class="position-relative">
    <div class="d-flex" :class="{ 'ar-list-item': isArabicLocale }">
      <section
        class="sidebar-wrapper"
        v-if="isExactSubscription === false"
        :class="[sidebarMobileClasses, { 'sidebar-wrapper-mobile': mobile }]"
      >
        <div
          style="background-color: white"
          :class="[mobile ? 'sidebar-heading-mobile' : '']"
          class="sidebar-heading text-center bg-white text-uppercase border-bottom"
        >
          <div
            :class="[
              mobile ? 'head-content-mobile' : '',
              isArabicLocale ? 'ml-0' : 'mr-0',
            ]"
            class="head-content justify-content-start w-100"
          >
            <b-container fluid>
              <b-row cols="12" class="p-1">
                <b-col cols="10" class="p-1">
                  <div
                    class="h-100 w-100 d-flex items-center justify-content-center"
                  >
                    <img
                      v-if="companyLogo && logoLoaded"
                      @load="
                        () => {
                          this.logoLoaded = true;
                        }
                      "
                      class="logo-img mr-2"
                      :class="[isArabicLocale ? 'ml-2' : 'mr-2']"
                      :src="image"
                      alt=""
                    />
                    <img
                      src="@/assets/default-placeholder.png"
                      alt=""
                      class="logo-img mr-2"
                      :class="[isArabicLocale ? 'ml-2' : 'mr-2']"
                      v-else
                    />
                    <p
                      class="my-0 font-weight-bold"
                      style="text-align: start; font-size: 16px"
                    >
                      {{ companyName }}
                    </p>
                  </div>
                </b-col>
              </b-row>
            </b-container>
            <font-awesome-icon
              icon="fa-solid fa-bars"
              v-if="mdAndDown"
              class="cursor"
              @click="setSidebar(false)"
              style="font-size: 1.4em"
            />
            <font-awesome-icon
              icon="fa-solid fa-bars"
              v-else
              :class="[mobile ? 'menu-toggle-mobile' : '']"
              class="menu-toggle"
              @click="closeSidebar"
              style="font-size: 1.4em"
            />
          </div>
          <font-awesome-icon
            icon="fa-solid fa-bars"
            v-if="mdAndDown"
            class="cursor"
            @click="setSidebar(false)"
            style="font-size: 1.4em"
          />
          <font-awesome-icon
            icon="fa-solid fa-bars"
            v-else
            :class="[mobile ? 'menu-toggle-mobile' : '']"
            class="menu-toggle"
            @click="closeSidebar"
            style="font-size: 1.4em"
          />
        </div>

        <div
          :class="[mobile ? 'list-group-mobile' : '']"
          class="list-group list-group-flush"
        >
          <router-link
            to="/dashboard"
            v-if="ability.can('read', 'Dashboard')"
            class="list-group-item list-group-item-action"
            exact
            @click.native="setSidebar(false)"
          >
            <font-awesome-icon
              icon="fa-solid fa-layer-group"
              class="item-img"
            />
            <span :class="[mobile ? 'span-mobile' : '']" class="hidee">{{
              !mobile ? $t("sidebar.dashboard") : ""
            }}</span>
          </router-link>

          <a
            class="list-group-item-dropdown listgroup-item-action list-group-item second-text position-relative"
            v-if="
              ability.can('read', 'Sales') ||
              ability.can('read', 'Quotes') ||
              ability.can('read', 'Customers') ||
              ability.can('read', 'Products & Services')
            "
            href="#dashboardSubmenu"
            data-toggle="collapse"
            aria-expanded="false"
            @click="rotateArrow(1)"
            id="dropdown"
          >
            <!-- @click="rotateArrow(1)" -->
            <div class="d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <font-awesome-icon
                  icon="fa-solid fa-dollar-sign"
                  class="item-img"
                />
                <div class="d-flex justify-content-between align-items-center">
                  <span :class="[mobile ? 'span-mobile' : '']" class="hidee">{{
                    !mobile ? $t("sidebar.sales") : ""
                  }}</span>
                  <font-awesome-icon
                    class="arrow"
                    :class="{ 'd-none': mobile, 'mr-22': isArabicLocale }"
                    id="arrow1"
                    icon="fa-solid fa-chevron-down"
                  />
                </div>
              </div>
            </div>
          </a>
          <ul
            :class="{ 'padding-0': isArabicLocale }"
            class="collapse"
            id="dashboardSubmenu"
            style="padding-left: 0px"
          >
            <router-link
              v-if="ability.can('read', 'Sales')"
              class="sub-list-group-item listgroup-item-action"
              :class="[mobile ? 'sub-list-group-item-mobile' : '']"
              to="/sales"
              active-class="drop-item-active"
              exact
              @click.native="setSidebar(false)"
            >
              <span
                class="bullet"
                :class="[mobile ? 'bullet-mobile' : '']"
              ></span>
              {{ $t("sidebar.sales") }}
            </router-link>

            <router-link
              v-if="ability.can('read', 'Quotes')"
              class="sub-list-group-item listgroup-item-action"
              :class="[mobile ? 'sub-list-group-item-mobile' : '']"
              to="/quotes"
              active-class="drop-item-active"
              exact
              @click.native="setSidebar(false)"
            >
              <span
                class="bullet"
                :class="[mobile ? 'bullet-mobile' : '']"
              ></span>
              {{ $t("sidebar.quotes") }}
            </router-link>

            <router-link
              v-if="ability.can('read', 'Customers')"
              class="sub-list-group-item listgroup-item-action"
              :class="[mobile ? 'sub-list-group-item-mobile' : '']"
              to="/customers"
              active-class="drop-item-active"
              exact
              @click.native="setSidebar(false)"
            >
              <span
                class="bullet"
                :class="[mobile ? 'bullet-mobile' : '']"
              ></span>
              {{ $t("sidebar.customers") }}
            </router-link>

            <router-link
              v-if="ability.can('read', 'Products & Services')"
              class="sub-list-group-item listgroup-item-action"
              :class="[mobile ? 'sub-list-group-item-mobile' : '']"
              to="/products"
              active-class="drop-item-active"
              exact
              @click.native="setSidebar(false)"
            >
              <span
                class="bullet"
                :class="[mobile ? 'bullet-mobile' : '']"
              ></span>
              {{ $t("sidebar.products & services") }}
            </router-link>
          </ul>

          <a
            class="list-group-item-dropdown listgroup-item-action list-group-item second-text position-relative"
            v-if="
              ability.can('read', 'Expenses') ||
              ability.can('read', 'Bills') ||
              ability.can('read', 'Vendors')
            "
            href="#dashboardSubmenuExpenses"
            data-toggle="collapse"
            aria-expanded="false"
            @click="rotateArrow(2)"
            id="dropdown"
          >
            <!-- @click="rotateArrow(1)" -->
            <div class="d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <font-awesome-icon
                  icon="fa-solid fa-sack-dollar"
                  class="item-img"
                  width="20px"
                  height="20px"
                />
                <div class="d-flex justify-content-between align-items-center">
                  <span :class="[mobile ? 'span-mobile' : '']" class="hidee">{{
                    !mobile ? $t("sidebar.expenses") : ""
                  }}</span>
                  <font-awesome-icon
                    class="arrow"
                    :class="{ 'd-none': mobile, 'mr-22': isArabicLocale }"
                    id="arrow2"
                    icon="fa-solid fa-chevron-down"
                  />
                </div>
              </div>
            </div>
          </a>
          <ul
            :class="{ 'padding-0': isArabicLocale }"
            class="collapse"
            id="dashboardSubmenuExpenses"
            style="padding-left: 0px"
          >
            <router-link
              v-if="ability.can('read', 'Expenses')"
              class="sub-list-group-item listgroup-item-action"
              :class="[mobile ? 'sub-list-group-item-mobile' : '']"
              to="/expenses"
              active-class="drop-item-active"
              exact
              @click.native="setSidebar(false)"
            >
              <span
                class="bullet"
                :class="[mobile ? 'bullet-mobile' : '']"
              ></span>
              {{ $t("sidebar.expenses") }}
            </router-link>

           

            <router-link
              v-if="ability.can('read', 'Vendors')"
              class="sub-list-group-item listgroup-item-action"
              :class="[mobile ? 'sub-list-group-item-mobile' : '']"
              to="/vendors"
              active-class="drop-item-active"
              exact
              @click.native="setSidebar(false)"
            >
              <span
                class="bullet"
                :class="[mobile ? 'bullet-mobile' : '']"
              ></span>
              {{ $t("sidebar.vendors") }}
            </router-link>
          </ul>

          <router-link
            to="/reports"
            v-if="ability.can('read', 'Reports')"
            class="list-group-item list-group-item-action second-text"
            exact
            @click.native="setSidebar(false)"
          >
            <font-awesome-icon icon="fa-solid fa-square-pen" class="item-img" />
            <span :class="[mobile ? 'span-mobile' : '']" class="hidee">{{
              !mobile ? $t("sidebar.reports") : ""
            }}</span>
          </router-link>

          <router-link
            v-if="planType && ability.can('read', 'Projects')"
            to="/projects"
            class="list-group-item list-group-item-action second-text"
            exact
            @click.native="setSidebar(false)"
          >
            <font-awesome-icon
              icon="fa-solid fa-diagram-project"
              class="item-img"
              width="10px"
              height="10px"
            />
            <span :class="[mobile ? 'span-mobile' : '']" class="hidee">{{
              !mobile ? $t("sidebar.projects") : ""
            }}</span>
          </router-link>

          <router-link
            to="/chartsOfAccounts"
            v-if="ability.can('read', 'Charts Of Accounts')"
            class="list-group-item list-group-item-action second-text"
            exact
            @click.native="setSidebar(false)"
          >
            <font-awesome-icon
              icon="fa-solid fa-square-poll-vertical"
              class="item-img"
            />
            <span :class="[mobile ? 'span-mobile' : '']" class="hidee">{{
              !mobile ? $t("sidebar.charts of accounts") : ""
            }}</span>
          </router-link>
 

          <div
            class="list-group-item list-group-item-action second-text position-relative"
            @click="openSendfeedbackPopup"
          >
            <font-awesome-icon icon="fa-solid fa-headset" class="item-img" />
            <span :class="[mobile ? 'span-mobile' : '']" class="hidee">{{
              !mobile ? $t("sidebar.support") : ""
            }}</span>
            <!-- <span :class="[mobile ? 'span-mobile' : '']" class="beta-in-sidebar">Beta!</span> -->
          </div>
        </div>

        <div class="version-container">
          <div
            :class="[mobile ? 'head-content-mobile' : '']"
            class="head-content"
          >
            <img
              class="logo-img mx-0 mb-2"
              src="../assets/dashboard-layout/tas-logo.png"
              alt=""
            />
            <p class="logo-text d-inline-block my-0 mx-2">TAS</p>
          </div>

          <div class="version">v 2.0.1</div>
        </div>
      </section>

      <!-- sidebar ends here -->
      <div id="page-content-wrapper" class="">
        <div v-if="mdAndDown && sidebar" class="overlay-content"></div>
        <section style="background-color: white">
          <section v-if="connectionStatus" class="d-block">
            <router-view @open-sidebar="setSidebar(true)"></router-view>
          </section>
          <section v-else>
            <div
              class="d-flex justify-content-center align-items-center flex-column"
            >
              <img
                style="height: 85vh; width: 1000px"
                src="../assets/bad-connection.png"
                alt=""
              />
              <p class="d-inline-block" style="font-size: 24px; color: #002873">
                No Internet Connection!
              </p>
            </div>
          </section>

          <feedback-popup></feedback-popup>

          <add-term-popup v-on:updateTerms="getNewTerms"></add-term-popup>

          <add-tax-popup
            :coa="accountTypes"
            v-on:updateTaxes="getNewTexes"
          ></add-tax-popup>

          <add-class-popup v-on:updateClasss="getNewClasses"></add-class-popup>

          <add-product-popup
            v-on:updateProductsEvent="getNewProducts"
            v-on:updateInventoryProductsEvent="getNewInventoryProducts"
            v-on:updateNoninventoryAndserviceProductsEvent="
              getNewNoninventAndServiceProducts
            "
          ></add-product-popup>

          <add-customer-popup
            v-on:updateCustomers="getNewCustomers"
            :currencies="currencies"
            :chartsOfAccounts="accountTypes"
          ></add-customer-popup>

          <add-branches-popup></add-branches-popup>
          <edit-subscribe-modal></edit-subscribe-modal>

          <add-account-popup
            :accountTypes="accountTypes"
            v-on:updatePaymentAccounts="getNewPaymentAccounts"
          ></add-account-popup>

          <add-supplier-popup
            :chartsOfAccounts="accountTypes"
            v-on:updateVendors="getNewVendors"
            :terms="terms"
            :currencies="currencies"
          ></add-supplier-popup>

          <add-invoice-popup
            v-if="!invoiceLoading || ability.can('create', 'Sales')"
            :customers="customers"
            :terms="terms"
            :payment_methods="payment_methods"
            :products="products"
            :projects="projects"
            :currencies="currencies"
            :taxes="taxes"
          ></add-invoice-popup>

          <add-quote-popup
            v-if="!quotesLoading || ability.can('create', 'Quotes')"
            :customers="customers"
            :terms="terms"
            :payment_methods="payment_methods"
            :products="products"
            :currencies="currencies"
            :taxes="taxes"
          ></add-quote-popup>

          <add-expense-popup
            v-if="!expensesLoading || ability.can('create', 'Expenses')"
            :payment_methods="payment_methods"
            :expensesAndProducts="expensesAndProducts"
            :vendors="vendors"
            :currencies="currencies"
            :taxes="taxes"
          ></add-expense-popup>

          <choose-add-popup></choose-add-popup>

          <add-bill-popup
            v-if="!billLoading || ability.can('create', 'Bills')"
            :terms="terms"
            :expensesAndProducts="expensesAndProducts"
            :vendors="vendors"
            :currencies="currencies"
            :taxes="taxes"
            :itemsForBill="itemsForBill"
          ></add-bill-popup>

          <add-journal-entry-popup
          ></add-journal-entry-popup>
          <add-project-popup
            :customers="customers"
            v-on:updateProjects="getNewProjects"
          ></add-project-popup>
          <edit-project-popup
         ></edit-project-popup>
        </section>

        <footer
          style="z-index: 3; max-height: 50px"
          class="footer"
          :class="{ 'ar-footer': isArabicLocale }"
          id="footer"
        >
          <p>{{ $t("dashboard.rightsReserved", { year: currentYear }) }}</p>
        </footer>
      </div>
    </div>
    
    <vue-internet-checker @status="status" @event="event" />
  </main>
</template>

<script>
import { BCol, BContainer, BRow } from "bootstrap-vue";

const Navbar = () =>
  import(/* webpackPrefetch: true */ "../components/dashboard layout/Navbar");
const FeedbackPopup = () =>
  import(/* webpackPrefetch: true */ "../components/popups/FeedbackPopup.vue");
const AddInvoicePopup = () =>
  import(
    /* webpackPrefetch: true */ "../modules/sales/components/AddInvoicePopup.vue"
  );
const AddTermPopup = () =>
  import(
    /* webpackPrefetch: true */ "../modules/bill/components/AddTermPopup.vue"
  );
const AddProductPopup = () =>
  import(
    /* webpackPrefetch: true */ "../modules/products/components/AddProductPopup.vue"
  );
const AddCustomerPopup = () =>
  import(
    /* webpackPrefetch: true */ "../modules/customers/components/AddCustomerPopup"
  );
const AddProjectPopup = () =>
  import(
    /* webpackPrefetch: true */ "../modules/project/components/AddProjectPopup.vue"
  );
  
  const EditProjectPopup = () =>
  import(
    /* webpackPrefetch: true */ "../modules/project/components/EditProjectPopup.vue"
  );
  
const AddQuotePopup = () =>
  import(
    /* webpackPrefetch: true */ "../modules/quotes/components/AddQuotePopup"
  );
const AddSupplierPopup = () =>
  import(
    /* webpackPrefetch: true */ "../modules/vendors/components/AddSupplierPopup.vue"
  );
const AddTaxPopup = () =>
  import(
    /* webpackPrefetch: true */ "../modules/taxes/components/AddTaxPopup.vue"
  );
const AddClassPopup = () =>
  import(/* webpackPrefetch: true */ "../components/popups/AddClassPopup.vue");
const AddAccountPopup = () =>
  import(
    /* webpackPrefetch: true */ "../modules/chartsOfAccounts/components/AddAccountPopup.vue"
  );
const AddExpensePopup = () =>
  import(
    /* webpackPrefetch: true */ "../modules/expenses/components/AddExpensePopup.vue"
  );
const ChooseAddPopup = () =>
  import(/* webpackPrefetch: true */ "../components/popups/ChooseAddPopup.vue");
const AddBillPopup = () =>
  import(
    /* webpackPrefetch: true */ "../modules/bill/components/AddBillPopup.vue"
  );
const AddJournalEntryPopup = () =>
  import(
    /* webpackPrefetch: true */ "@/components/popups/journalEntry/add/index.vue"
  );
const AddBranchesPopup = () =>
  import("../modules/settings/components/branches/AddBranchesModal.vue");

import EditSubscribeModal from "../modules/settings/components/bill and subscription components/EditSubscribe.vue";

import { mapActions, mapGetters } from "vuex";
import vueInternetChecker from "vue-internet-checker";
import defineAbilityFor from "@/casl/defineAdminAbility";
import ls from "localstorage-slim";
import arabicLocale from "@/mixins/arabicLocale.js";
import LoadingComp from "../components/loading/LoadingComp.vue";

ls.config.encrypt = true;

const shrink_btn = document.querySelector(".menu-toggle");
const search = document.querySelector(".search");
const sidebar_links = document.querySelectorAll(".sidebar-links a");
const active_tab = document.querySelector(".active-tab");
const shortcuts = document.querySelector(".sidebar-links h4");
const tooltip_elements = document.querySelectorAll(".tooltip-element");
let activeIndex;

export default {
  mixins: [arabicLocale],
  components: {
    Navbar,
    FeedbackPopup,
    EditProjectPopup,
    AddInvoicePopup,
    AddTermPopup,
    AddProductPopup,
    AddCustomerPopup,
     AddProjectPopup,
     AddQuotePopup,
    AddSupplierPopup,
    AddTaxPopup,
     AddClassPopup,
    AddAccountPopup,
    AddExpensePopup,
    ChooseAddPopup,
    AddBillPopup,
    vueInternetChecker,
    AddJournalEntryPopup,
    AddBranchesPopup,
    EditSubscribeModal,
     LoadingComp,
    BContainer,
    BRow,
    BCol,
  },
  data() {
    return {
      mobile: false,
      connectionStatus: true,
      onLine: null,
      role: ls.get("perToken"),
      ability: defineAbilityFor(ls.get("perToken")),
      screenWidth: window.innerWidth,
      sidebar: false,
      closeIcon: require("@/assets/dashboard-layout/close.svg"),
      loading: true,
      image: "",
      imageUrl: null,
      logoLoaded: false,
       

    };
  },

  computed: {
    ...mapGetters(["is_sidebar_closed"]),
    isExactSubscription() {
      const expectedUrl = `${window.location.origin}/subscription`;
    return window.location.href === expectedUrl;
  },
    companyName() {
      return this.$store.state.companyName;
    },

    companyLogo() {
      return localStorage.getItem("companyLogo");
    },

    accountTypes() {
      return this.$store.state.chartsOfAccounts.accountTypes;
    },

    showAccountTypes() {
      return this.$store.state.chartsOfAccounts.accountTypes;
    },

    customers() {
      return this.$store.state.customers.customersForInvoice;
    },

    planType() {
      return ls.get("code") === "XA07" ? true : false;
    },

    quotesLoading() {
      return this.$store.state.quotes.loading;
    },

    projectLoading() {
      return this.$store.state.projects.viewLoading;
    },

    invoiceLoading() {
      return this.$store.state.sales.loading;
    },

    expensesLoading() {
      return this.$store.state.expenses.loading;
    },

    billLoading() {
      return this.$store.state.bill.loading;
    },

    autoSelectedCustomer() {
      return this.$store.state.customers.autoSelectedCustomer;
    },

    terms() {
      return this.$store.state.bill.terms;
    },

    payment_methods() {
      return this.$store.state.sales.payment_methods;
    },

    expensesAndProducts() {
      return this.$store.state.expenses.expensesAndProducts;
    },

    vendors() {
      return this.$store.state.vendors.vendors;
    },

    products() {
      return this.$store.state.products.listProducts;
    },

    projects() {
      return this.$store.state.projects.projects;
    },

    currencies() {
      return this.$store.state.users.currencies;
    },

    taxes() {
      return this.$store.state.taxes.taxsList;
    },
    itemsForBill() {
      return this.$store.state.expenses.itemsForBill;
    },
    currentYear() {
      return new Date().getFullYear();
    },
    mdAndDown() {
      return this.screenWidth < 1024;
    },
    sidebarMobileClasses() {
      let classes = "";
      this.mdAndDown && (classes += "sidebar-mobile ");
      this.sidebar && (classes += "show-sidebar-mobile");
      return classes;
    },
  },

  methods: {
    ...mapActions(["is_sidebar_closed_action"]),
  
 

 
   
    closeSidebar() {
      document.body.classList.toggle("shrink");
      // setTimeout(this.moveActiveTab, 400);
      const shrink_btn = document.querySelector(".menu-toggle");
      shrink_btn.classList.add("hovered");

      setTimeout(() => {
        shrink_btn.classList.remove("hovered");
      }, 500);
      // (this.mobile, this.is_sidebar_closed);closeSidebar
      this.mdAndDown ? (this.mobile = false) : (this.mobile = !this.mobile);
      // (this.mobile, this.is_sidebar_closed);

      if (this.is_sidebar_closed) {
        this.is_sidebar_closed_action(false);
      } else {
        this.is_sidebar_closed_action(true);
      }
      $(".collapse").collapse("hide");
    },

    moveActiveTab() {
      // let topPosition = activeIndex * 58 + 2.5;
      //
      // if (activeIndex > 3) {
      //   topPosition += shortcuts.clientHeight;
      // }
      //
      // active_tab.style.top = `${topPosition}px`;
    },

    getNewTerms() {
      this.$store.dispatch("bill/getTermsAction");
    },

    getNewProducts() {
      this.$store.dispatch("products/getProductsForInvoices");
    },

    getNewCustomers() {
      this.$store.dispatch("customers/GetCustomerForInvoiceAction");
    },

    getNewProjects() {
      this.$store.dispatch("projects/get_projects_action");
    },

    getNewVendors() {
      this.$store.dispatch("vendors/getVendorsAction");
    },

    getNewTexes() {
      this.$store.dispatch("taxes/get_taxsList_action");
    },

    getNewClasses() {
      this.$store.dispatch("products/getClassesAction");
    },

    getNewPaymentAccounts() {
      // this.$store.dispatch("chartsOfAccounts/getPaymentAccountsAction");
      this.$store.dispatch("chartsOfAccounts/get_coaForExpenses_action");
    },

    getNewInventoryProducts() {
      this.$store.dispatch("products/getInventoryProductsAction");
    },

    getNewNoninventAndServiceProducts() {
      this.$store.dispatch("products/nonInventory_service_Action");
    },

    getNewCOA() {
      this.$store.dispatch("chartsOfAccounts/accountTypeAction");
    },

    rotateArrow(index) {
      let arrow = document.getElementById(`arrow${index}`);
      this.openDropdown = true;
      const dropdown = document.getElementById("dashboardSubmenu");
      const secondDropdown = document.getElementById(
        "dashboardSubmenuExpenses"
      );

      if (index === 1) {
        secondDropdown.classList.remove("show");
      } else if (index === 2) {
        dropdown.classList.remove("show");
      } else if (index === 3) {
        dropdown.classList.remove("show");
        secondDropdown.classList.remove("show");
      }
  
    },

    openSendfeedbackPopup() {
      this.setSidebar(false);
      const sendfeedbackModal = document.getElementById("sendfeedbackModal");
      sendfeedbackModal.style.display = "flex";
    },

    status(ele) {
      // (ele);
      this.onLine = ele;
      this.connectionStatus
        ? (this.connectionStatus = false)
        : (this.connectionStatus = true);
    },

    event(ele) {
      // (ele);
    },
    onResize() {
      this.screenWidth = window.innerWidth;
    },
    setSidebar(val) {
      this.sidebar = val;
      this.rotateArrow(3);
    },
    handleSidebarOutside() {
      this.mdAndDown && this.sidebar && this.setSidebar(false);
    },
  },

  created() {
  
    this.$store.dispatch("settings/get_companyData_action").then(() => {
      window.addEventListener("resize", this.onResize);
      this.imageUrl = localStorage.getItem("companyLogo");
       const headers = {
        XTenant: localStorage.getItem("xtenant"),
      };

      fetch(this.imageUrl, { headers })
        .then((response) => {
          if (response.ok) {
            return response.blob();
          } else {
            throw new Error("Image loading failed");
          }
        })
        .then((blob) => {
          this.image = URL.createObjectURL(blob);
          this.logoLoaded = true;
         })
        .catch((error) => {
          console.error(error);
        });
    });
  },

  mounted() {
    if (navigator.onLine) {
      this.connectionStatus = true;
    } else {
      this.connectionStatus = false;
    }

    if (localStorage.loginName) {
      this.name = localStorage.loginName;
    }

    if (localStorage.loginEmail) {
      this.email = localStorage.loginEmail;
    }
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  watch: {
   
    is_sidebar_closed(val) {
      if (val) {
        const dropdown = document.getElementById("dashboardSubmenu");
        const secondDropdown = document.getElementById(
          "dashboardSubmenuExpenses"
        );
        dropdown.classList.contains("show") &&
          dropdown.classList.remove("show");

        secondDropdown.classList.contains("show") &&
          secondDropdown.classList.remove("show");
      } 
    
    },
  },
};
</script>

<style scoped>
@import url("../assets/styles/footer.css");

a {
  text-decoration: none;
}

#wrapper {
  overflow-x: hidden;
  background-color: #fafafe;
}

.sidebar-wrapper {
  min-height: 100vh;
  width: 225px;
  background-color: #002873;
  position: relative;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

.sidebar-heading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hidee {
  transition: opacity 0.7s 0.5s;
}

body.shrink .hidee {
  opacity: 0;
  pointer-events: none;
  transition-delay: 0s;
}

/* side bar heading styles */
.sidebar-wrapper .sidebar-heading {
  font-size: 1.2rem;
  position: relative;
  display: flex;
  align-items: center;
}

.logo-text {
  margin-top: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 52px;
  letter-spacing: 0.07em;
  color: #fff;
}

.company-logo-text {
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0.07em;
  color: #002873;
  cursor: pointer;
  position: absolute;
  right: 3rem;
  top: 40%;
}

.menu-toggle {
  position: absolute;
  cursor: pointer;
  padding: 0 0.3rem;
  right: 1rem;
  pointer-events: none;
}

/* .sidebar-wrapper::before {
    content: "";
    position: absolute;
    width: 2rem;
    height: 100%;
    top: 0;
    left: 100%;
} */

.sidebar-wrapper:hover .menu-toggle {
  /* transform: translateY(-50%) translateX(0px);
    opacity: 1; */
  pointer-events: all;
}

.list-group .hidee {
  position: relative;
  padding: 0;
  font-weight: 400;
  font-size: 0.9rem;
  display: inline;
  align-items: center;
  height: 20px;
}

.sidebar-wrapper .list-group {
  width: 225px;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

.list-group-item {
  border: none;
  padding: 10px 25px;
  background: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.sub-list-group-item {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #959cb6;
  border: none;
  padding: 10px 27px;
  background: none;
  width: 100%;
  display: block;
}

.sub-list-group-item a {
  text-decoration: none !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #959cb6;
  width: 100%;
}

.rotateArrow {
  transition: 0.2s all;
  transform: rotate(180deg);
}

.rerotateArrow {
  transition: 0.2s all;
  transform: rotate(360deg);
}

.list-group-item img {
  margin-right: 1.5rem;
}

.list-group-item svg {
  margin-right: 1.5rem;
}

.list-group-item span,
.sub-list-group-item a {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.list-group-item:hover,
.sub-list-group-item:hover,
.drop-item-active {
  background: white;
  text-decoration: none;
  color: #002873;
  font-weight: 500;
}

.router-link-active {
  background-color: white;
  padding: 10px 25px;
  color: #002873;
}

.list-group-item.active {
  background-color: #00b189;
  color: #fff;
}

.version-container {
  position: absolute;
  bottom: 0px;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 0px;
}

.logo-img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

.logo-placeholder {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #b0b0b12d;
  color: #002873;
  /* margin: 0px auto; */
}

.head-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-content-wrapper {
  height: 100%;
}

/* mobile size styles */

.sidebar-wrapper-mobile {
  width: 70px;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

.sidebar-wrapper .list-group-mobile {
  width: 70px;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

.span-mobile,
.head-content-mobile {
  display: none;
}

.menu-toggle-mobile {
  right: 30%;
  top: 40%;
}

.sidebar-heading-mobile {
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.collapse {
  /* padding-left: 0px !important; */
  list-style-type: none;
}

.bullet {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #959cb6;
  border-radius: 50%;
  margin: 0rem 1rem;
}

/* sales drop down style */
.arrow {
  position: absolute;
  right: 22px;
  transition: 0.2s all;
}

.beta-in-sidebar {
  color: var(--yellow);
  position: absolute;
  right: 22px;
  font-style: italic !important;
}

.item-img {
  width: 16px;
  height: 16px;
  display: inline-block;
}

/* (-webkit-max-device-pixel-ratio: 3) and (-webkit-device-pixel-ratio: 1) and  */
@media (min-width: 768px) {
  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }
}

@media (-webkit-max-device-pixel-ratio: 1.25) {
  .sidebar-wrapper {
    min-height: 100vh;
  }
}

@media (-webkit-min-device-pixel-ratio: 1.25) {
  .sidebar-wrapper {
    min-height: 100vh;
  }
}

@media screen and (width: 1920px) {
  .sidebar-wrapper {
    min-height: 100vh;
    /* height: 100%; */
  }
}

.bullet-mobile {
  display: none;
}

.sub-list-group-item-mobile {
  padding: 10px 0px 10px 10px;
  font-weight: 200;
  font-size: 12px;
}

.sidebar-mobile {
  width: 0px;
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 9999;
  transition: width 0.3s;
}

.show-sidebar-mobile {
  overflow-y: auto;
  width: 225px;
}

@media screen and (max-width: 768px) {
  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }
}

.cursor {
  cursor: pointer;
}

.overlay-content {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  opacity: 0.5;
  background: #4b465c;
}

.ar-list-item .menu-toggle {
  right: auto;
  left: 1rem;
}

.ar-footer p {
  margin: 10px -15% 10px 0px;
}

.ar-list-item .list-group-item img {
  margin-right: 0px;
  margin-left: 1.5rem;
}

.ar-list-item .list-group-item svg {
  margin-right: 0px;
  margin-left: 1.5rem;
}

.ar-list-item .arrow {
  right: auto;
  left: 22px;
}

.ar-list-item .show {
  text-align: right;
}

.ar-list-item .sub-list-group-item {
  text-align: right;
}

.remove-right {
  margin-right: 0px;
}

.padding-0 {
  padding: 0px;
}

.mr-22 {
  margin-right: 1.5rem !important;
  margin-left: 0px !important;
}

.justify-content-start {
  justify-content: flex-start;
}
::v-deep .vs__no-options 
{
  display: none !important;
}
.add-new-list-btn {
  cursor: pointer;
  margin: 1px 10px !important;
  background-color : #f5f5f5;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

}
.d-footer{
  display: flex !important;
    align-items: center !important;
    justify-content: end !important;
}
@media (max-width: 992px) {
  ::v-deep .el-dialog {
    width: 50% !important;
  }
}
@media (max-width: 800px) {
  ::v-deep .el-dialog {
    width: 70% !important;
  }
}
.fixed-btn-plus{
  background-color: #00709c !important;
  color: #fff !important;
  position:fixed;
  bottom:10px;
  right:10px;
  z-index:9;
}
.fixed-btn-plus:hover{
  background-color: #005b7f !important;
  
}


.el-select-dropdown__list {
  padding: 0px !important;
}

.el-select-dropdown__list .add-product {
    background: #f3f3f3;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    padding: 4px 0px;
}

.el-select-dropdown__item {
  height: auto !important;
}
</style>